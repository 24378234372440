// src/App.js
import React from 'react';
import AppRouter from './AppRouter';
import { CssBaseline } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#3f51b5',
    color: 'white',
    padding: '10px 0',
    textAlign: 'center',
  },
});

const App = () => {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <AppRouter />
    </>
  );
};

export default App;