import Button from '@mui/material';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    inpStyle: {
        width: '100%',
        height: '50px',
        color:"#201e33!important",
        fontSize:"16px!important",
        borderRadius:"50px!important",
        padding:"0px 30px!important",
        fontFamily:"Satoshi-Medium!important",
        background:"#fff",
        marginTop:"20px",
        border:"none"
    },
      error:{
        fontSize:"12px",
        color:"red",
        marginTop:"1    0px",
        marginLeft:"10px"
      }
  });

  function ButlerInput(props) {
    const classes = useStyles();

    return(
        <>
        <input value={props.value} name={props.name} placeholder={props.placeholder} onChange={props.onChange} className={classes.inpStyle} />
        {props.errorText ? <p className={classes.error}> {props.errorText} </p> : "" }
        </>
    )
        
    
  }

  export default ButlerInput