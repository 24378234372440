import Button from '@mui/material/Button';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    buttonStyle: {
        width: '100%',
        height: '50px',
        cursor: 'pointer!important',      
        color:"#201e33!important",
        fontSize:"16px!important",
        borderRadius:"50px!important",
        padding:"0px 30px!important",
        fontFamily:"Satoshi-Medium!important",
    },
    yelloBG:{
        background:"#fbb81b!important",
    },
    wBG:{
        background:"#fff!important",
    }
    
  });

  function ButlerButon(props) {
    const classes = useStyles();
    const checkBg = () => {
        if(props.variant=="primary"){
            return classes.yelloBG
        }
        return  classes.wBG
    }
    return(
        <Button style={{textTransform:!props.textTransform ? 'initial' : 'initial'}} className={classes.buttonStyle +" " + checkBg()} onClick={props.onClick}> {props.text} </Button>
    )
        
    
  }

  export default ButlerButon