import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Grid } from '@mui/material';
import Logo2 from "../../assets/logo2.png";
import fb from "../../assets/fb.png";
import insta from "../../assets/insta.png"
const useStyles = makeStyles({
    footer: { width: "100%", padding: "50px 0px", marginTop: "160px", background: "#201e33", color: "#fff", "& a":{color:"#989898"}, "& .MuiGrid-item":{lineHeight:"40px"} },
    '@media (max-width:900px)': {
        mobileGrid:{marginTop: "30px", "& .MuiGrid-item":{width:"100%", textAlign:"ceenter"}},
        sicial:{justifyContent:"left!important"},
        mobileMarg:{paddingTop:"10px!important"},
    }
})
const Footer = () => {
    const classes = useStyles();
    return (
        <div className={classes.footer}>
            <Container>
                <Grid container spacing={10}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}> <img src={Logo2} /> </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container justifyContent="flex-end" spacing={3} className={classes.mobileGrid}>
                                    <Grid item> Hveringevej 1 </Grid>
                                    <Grid item>  5230 Odense </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.mobileMarg}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container justifyContent="flex-end" spacing={3} className={classes.mobileGrid}>
                                    <Grid item> 
                                      <a target='_blank' href='https://production.butlerapp.dk/terms_of_use'> Brugervilkår </a>
                                    </Grid>
                                    <Grid item>
                                      <a target='_blank' href='https://production.butlerapp.dk/privacy_policy'> Privatlivspolitik </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Footer