// src/components/Home.js
import React, { useState } from 'react';
import { Container, Grid, Button } from '@mui/material';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import Header from "../components/header";
import img1 from "../../assets/img1.jpg"
import img2 from "../../assets/img2.jpg"
import img3 from "../../assets/img3.jpg"
import img4 from "../../assets/img4.jpg"
import img5 from "../../assets/img5.jpg"
import playStore from "../../assets/playStore.png";
import appStore from "../../assets/appStore.png";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButlerButon from "../../uiElements/button";
import ButlerInput from "../../uiElements/input";
import ButlerTextArea from "../../uiElements/textArea";
import Footer from "../../components/footer";
import Hellp from "../../components/help"
const useStyles = makeStyles({

  bottomContentBlock: {
    paddingTop: "160px",
    fontSize: "18px",
    "& .MuiAccordionDetails-root": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important"
    },
    "& .MuiAccordion-root": { boxShadow: "none", borderBottom: "1px solid #ddd" },
    "& .MuiAccordionSummary-root": { padding: "0px!important" },
    "& .MuiAccordionSummary-content": { fontFamily: "Satoshi-Bold!important", fontSize: "22px", padding: "20px 0px" }
  },
  heading: { fontSize: "58px", lineHeight: "64px", fontFamily: "Satoshi-Light!important", "& b": { fontFamily: "Satoshi-Bold!important" } },
  pText: { fontSize: "18px", marginTop: "30px" },
  gryCard: { background: "#f7f5f2", padding: "80px", borderRadius: "15px" },
  textCenter: { textAlign: "center" },
  playStore: { padding: "50px 0px 0px 0px", textAlign: "center" },
  playStoreLink: { textDecoration: 'none'  },
  yellowText: { color: "#fbb81b" },
  postion: { position: "relative" },
  nemtImg: { position: "absolute", top: "-40px", right: "-38px" },
  accordHeader: { textAlign: "center", marginBottom: "50px" },
  yellowCircle: { position: "relative", paddingLeft: "30px", "& span": { width: "10px", height: "10px", background: "#fbb81b", borderRadius: "50%", left: "0px", top: "8px", display: "inline-block", position: 'absolute' } },
  '@media (max-width:780px)': {
    bottomContentBlock: { paddingTop: "80px" },
    responsSpace: { paddingTop: "40px!important" },
    gryCard: { padding: "30px" },
    accordHeader: { marginBottom: "30px", textAlign: "left!important" },
    nemtImg: { display: "none" }
  },

  '@media (max-width:500px)': {
    nemtImg: { width: "50px", top: "-24px", right: "-30px" },
    bottomContentBlock: {
      paddingTop: "80px",
      "& .MuiAccordionSummary-content": { fontSize: "18px", padding: "10px 0px" }
    },
    heading: { fontSize: "40px", lineHeight: "48px!important" },
  },
  '@media (max-width:400px)': {
    nemtImg: { width: "25px", top: "-7px", right: "-8px" },

    bottomContentBlock: { paddingTop: "80px" }
  },
});

const Home = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    telefon: '',
    email: '',
    message: ''
  });

  const [error, setError] = useState({})

  const inpChange = (e) => {
    let { value, name } = e.target;
    setError(prev => {
      return { ...prev, [name]: "" }
    })
    setState(prev => {
      return { ...prev, [name]: value }
    })
  }

  const sendBeskd = async (e) => {
    e.preventDefault();
    let newError = {};
    let requiredInputError = 'Dette felt skal udfyldes'

    if (!state.telefon) {
      newError.telefon = requiredInputError;
    }
    if (!state.email) {
      newError.email = requiredInputError;
    }
    if (!state.message) {
      newError.message = requiredInputError;
    }

    setError(newError);

    if (Object.keys(newError).length === 0) {
      try {
        const response = await axios.post('https://byteable-email-gateway-rails.hq.byteable.dev/v1/butlerapp/contacts/send_message', {
          phone: state.telefon,
          email: state.email,
          message: state.message
        });

        alert('Tak for din besked Vi har modtaget din besked og vender tilbage til dig hurtigst muligt.');

        setState({
          telefon: '',
          email: '',
          message: ''
        });

      } catch (error) {
        alert('Email sending failed');
        console.error('Error sending email:', error);
      }
    }
  };


  return (
    <div classNam={classes.mainContainer}>
      <Hellp />
      <Header />
      <Container className={classes.container}>
        <div id="features" className={classes.bottomContentBlock}>
          <Grid container spacing={10}>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src={img1} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.responsSpace}>
              <h1 className={classes.heading}> Opret dig og <b> frigør </b> tid til dig selv og til din familie </h1>
              <p className={classes.pText}>
                Frigøre tid til din familie på ingen tid ved at oprette en konto på butlerapp. Det tager kun få enkle trin:
              </p>

              <p className={classes.pText}>
                1. Gå til App Store eller Googl Play og download butlerapp til din smartphone
              </p>

              <p className={classes.pText}>
                2. Åben appen og tilføj dit navn, din e-mail, samt din adresse, så vi kan matche dig med de bedste services i dit område
              </p>

              <p className={classes.pText}>
                3. Bum - du er klar!
              </p>



            </Grid>
          </Grid>
        </div>
        <div id="services" className={classes.bottomContentBlock}>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h1 className={classes.heading}> Få hjælp til alle dine daglige gøremål med <b> butlerapp<span className={classes.yellowText}>.</span> </b> </h1>
              <p className={classes.pText}>
                Frigør tid til din familie på ingen tid ved at oprette en konto på butlerapp.
              </p>

              <p className={classes.pText}>
                <b> Haveservice: </b> Græsslåning, hækklip, tagrenderens, græspleje osv
              </p>

              <p className={classes.pText}>
                <b>Rengøring:</b> Hjemmerengøring, vinduesvask, vinduespuds osv.
              </p>

              <p className={classes.pText}>
                <b> Bilpleje: </b>  Vask, polering, støvsugning, indvendig rengøring osv
              </p>
              <p className={classes.pText}>
                <b> Algerens:</b> Fjernelse af alger fra tag, terrasser, stier osv. Og meget mere!
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.responsSpace}>
              <img src={img2} />
            </Grid>
          </Grid>
        </div>

        <div id="fordele" className={classes.bottomContentBlock}>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img src={img3} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className={classes.responsSpace}>
              <h1 className={classes.heading}> Med <b>butlerapp<span className={classes.yellowText}>.</span></b> <br /> kan du </h1>
              <p className={classes.pText + " " + classes.yellowCircle}>
                <span> </span>Frigøre tid til familien eller fokusere på det, der virkelig betyder noget for dig.
              </p>

              <p className={classes.pText + " " + classes.yellowCircle}>
                <span> </span>Nyde pålidelige og professionelle services til konkurrencedygtige priser.
              </p>

              <p className={classes.pText + " " + classes.yellowCircle}>
                <span> </span>Tilpasse dine ydelser og tidsplaner efter dine behov og præferencer.
              </p>

              <p className={classes.pText + " " + classes.yellowCircle}>
                <span> </span>Nem månedlig betaling.
              </p>
            </Grid>
          </Grid>
        </div>
        <div className={classes.bottomContentBlock}>
          <div className={classes.gryCard + " " + classes.textCenter}>
            <h1 className={classes.heading}> Download <b className={classes.postion}>butlerapp<span className={classes.yellowText}>.</span> <img className={classes.nemtImg} src={img5} /> </b> i dag </h1>
            <div className={classes.playStore}>
              <a className={classes.playStoreLink} target='_blank' href="https://play.google.com/store/apps/details?id=com.butlerapp.byteable&hl=da&pli=1"> <img src={playStore} /> </a>
              <a className={classes.playStoreLink} target='_blank' href="https://apps.apple.com/dk/app/butlerapp/id6482354416?l=da"> <img src={appStore} /> </a>
            </div>
          <div>
        </div>
      </div>
    </div>

        <div id="faqs" className={classes.bottomContentBlock}>
          <h1 className={classes.heading + " " + classes.accordHeader} style={{ textAlign: "center" }}> Mest stillede <b>Spørgsmål </b> </h1>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Hvad er butlerapp?
            </AccordionSummary>
            <AccordionDetails>
              Med butlerapp kan du med vores app beregne priser og bestille udførelse af de dagligdagsopgaver, som du har brug for hjælp med, så du får mere tid til dig og din familie.
              Priserne er gennemskuelige og du betaler månedligt på et årsabonnement, så du altid kender dine udgifter. Få hjælp til alle dine daglige gøremål med butlerapp.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Hvorfor vælge butlerapp?
            </AccordionSummary>
            <AccordionDetails>
              Du skal vælge butlerapp, så du kan frigøre tid til det vigtige. Vi har samlet hjælp til de mest almindelige dagligdagsopgaver i én enkelt løsning. VI har derudover gjort det nemt og  overskueligt at beregne prisen og få delt betalingen op månedligt, hen over 12 måneder i et årsabonnement.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Hvem udfører opgaverne?
            </AccordionSummary>
            <AccordionDetails>
              Hos butlerapp har vi samlet nogle af landets bedste professionelle servicevirksomheder, så de kan hjælpe dig! Vi har derudover forhandlet priser, så du slipper for besværet.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Hvordan kan jeg se hvad det koster?
            </AccordionSummary>
            <AccordionDetails>
              Med butlerapp kan du med få oplysninger beregne din månedlig pris på lige netop den opgave, du gerne vil have hjælp til. Alle ydelserne kører på årsabonnementer, hvilket sikrer dig en gennemskuelig og fast pris. Nemt, ikke?
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Hvordan fungerer månedlig betaling?
            </AccordionSummary>
            <AccordionDetails>
              Når man har beregnet en ydelse i butlerapp, tilknytter man online betaling, hvorefter butlerapp automatisk hæver det aftalte beløb hver måned i den periode årsabonnementet løber.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Bliver min aftale automatisk fornyet?
            </AccordionSummary>
            <AccordionDetails>
              Aftalen kører som et årsabonnement i 12 måneder. Aftalen fornys ikke automatisk og ophører ved periodens udløb.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Hvad skal jeg gøre, når jeg får nyt betalingskort?
            </AccordionSummary>
            <AccordionDetails>
              Får du et nyt betalingskortkort, kan du let skifte til det nye kort i butlerapp. Dette gør du under Profil, hvor du vælger Betalingsmetoder og derefter +Tilføj betalingsmetode.
            </AccordionDetails>
          </Accordion>
        </div>

        <div id="kontakt" className={classes.bottomContentBlock}>
          <div className={classes.gryCard}>
            <Grid container spacing={10}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <h1 className={classes.heading}> Har du brug for <b>  hjælp </b> eller har du spørgsmål? </h1>
                <p className={classes.pText}>
                  Kontakt vores venlige kundeserviceteam, der er klar til at hjælpe dig med lige det du har brug for.
                </p> <br />
                <ButlerInput value={state.telefon} errorText={error.telefon} name="telefon" placeholder="Indtast telefonnummer" onChange={e => inpChange(e)} />
                <ButlerInput value={state.email} errorText={error.email} name="email" placeholder="Indtast e-mailadresse" onChange={e => inpChange(e)} />
                <ButlerTextArea value={state.message} errorText={error.message} name="message" placeholder="Skriv en besked" onChange={e => inpChange(e)} />
                <br /> <br />
                <ButlerButon text="Send besked" variant="primary" onClick={e => sendBeskd(e)} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className={classes.responsSpace}>
                <img src={img4} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Home;