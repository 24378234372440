import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import help from "../../assets/help.png";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import close from "../../assets/closeIcon.png";
export default function Hellp() {
    const [state, setState] = React.useState({

        left: false,

    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <h1 style={{marginTop:"50px", marginBottom:"20px"}}> <b> Kontakt butlerapp </b> </h1>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="mailto:hej@butlerapp.dk">
                        <ListItemIcon>
                            <img width="30" src={icon1} />
                        </ListItemIcon>
                        <ListItemText primary={"hej@butlerapp.dk"} />
                    </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <img width="30" src={icon2} />
                        </ListItemIcon>
                        <ListItemText primary={"Chat med os"} />
                    </ListItemButton>
                </ListItem> */}
                <ListItem disablePadding>
                    <ListItemButton component="a" href="tel:+4536919292">
                        <ListItemIcon>
                            <img width="30" src={icon3} />
                        </ListItemIcon>
                        <ListItemText primary={"+45 3691 9292"} />
                    </ListItemButton>
                </ListItem>
            </List>

        </Box>
    );

    return (
        <div style={{ position: "fixed", right: "20px", bottom: "45px", zIndex:"99" }}>
            <Button onClick={toggleDrawer("right", true)}> <img width="50" src={help} /> </Button>
            <Drawer
                anchor={"right"}
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
            >
               <div style={{position:"relative", width:"400px", maxWidth:"100%", padding:"30px"}}> 
                <img style={{position:"absolute", right:"25px", cursor:"pointer"}} src={close} width="30"  onClick={toggleDrawer("right", false)}/>
               {list("right")} </div> 
            </Drawer>
        </div>
    );
}