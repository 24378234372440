// src/components/Home.js
import React from 'react';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import bgImg from "../../../assets/bg1.jpg";
import bgImg2 from "../../../assets/bg2.jpg";
import MenuNav from "../../../components/nav";
import ButlerButon from "../../../uiElements/button";
import {scrollToSection} from "../../../commonFunctions/index";
const useStyles = makeStyles({
    mainContent: {
        width: "100%",
        minHeight: "951px",
        background: `URL(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition:"bottom",
        position: "relative"
    },
    contentBlock : {
        position:'absolute',
        bottom:"50px",
        width:"100%",
        color:"#fff"
    },
    yellowText:{color:"#fbb81b"},
    heading:{fontSize:"62px", color:"#fff", lineHeight:"80px"},
    text:{fontSize:"20px", marginTop:"20px", lineHeight:"26px"},
    '@media (min-width:1800px)': {
        mainContent: {
            backgroundSize: "cover",
        }
    },
    '@media (min-width:1400px)': {
        mainContent: {
            backgroundSize: "cover",
        }
    },
    '@media (max-width:900px)': {
        mainContent:{paddingBottom:"50px", backgroundPosition:"bottom left", minHeight:"auto", backgroundImage: `URL(${bgImg2})`, backgroundAttachment:"scroll",},
        contentBlock:{
            position:"relative",
            bottom:"0px",
            paddingTop:"100px"
        }
    },
    '@media (max-width:500px)': {
        heading:{fontSize:"40px", lineHeight:"48px!important", marginTop:"20px", lineHeight:"normal!important"},
        text:{lineHeight:'normal'},
        contentBlock:{"& .MuiGrid-item":{width:"100%"}}
      }
});

const Header = () => {
    const classes = useStyles();
    return (
        <div id="home" className={classes.mainContent}>
            <MenuNav />
            <div className={classes.contentBlock}> 
            <Container className={classes.container}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p className={classes.heading}>Frigør tid til familien med <b>butlerapp<span className={classes.yellowText}>.</span></b></p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <p className={classes.text}> Lad os håndtere dagligdagens gøremål, så du kan nyde kostbare øjeblikke sammen med dine kære.  </p>
                        <p className={classes.text}> Få hjælp til haveservice, rengøring, bilpleje og mange andre services på årsabonnement </p>
                        <Grid container spacing={2} style={{marginTop:"20px"}}>
                        <Grid item >  <ButlerButon text="Download app" variant="primary" onClick={() => window.open("https://2ly.link/1yA8V", "_blank")} /> </Grid>
                        <Grid item >  <ButlerButon onClick={() => scrollToSection('services')} textTransform="true" text="Se alle butlerapp services"  /> </Grid>
                        </Grid>
                       
                    </Grid>
                </Grid>
            </Container>
            </div>
        </div>
    );
};

export default Header;