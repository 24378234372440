import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '../../assets/menu_icon.png';
import { makeStyles } from '@mui/styles';
import ButlerButon from "../../uiElements/button";
import logo from "../../assets/logo.png";
import {scrollToSection} from "../../commonFunctions/index"
  const useStyles = makeStyles({
    logoStyle: {
        width: '140px',
        height: 'auto',
        cursor: 'pointer',
    },
    navDiv:{
      "& p":{fontFamily:'Satoshi-Bold !important'},
      "& li":{fontFamily:'Satoshi-Bold !important'}
    },
    '@media (max-width:900px)': {
      mobileButton:{marginRight:"10px"}
    },
    '@media (max-width:500px)': {
      logoStyle: {
        width:"100px"
      },
    mobileButton:{"& button":{fontSize:"14px!important", padding:"8px 15px!important", height:"40px!important"}}
    },
    '@media (max-width:380px)': {
      mobileButton:{"& button":{fontSize:"12px!important", padding:"8px 15px!important", height:"40px!important"}}
    },
    '@media (max-width:330px)': {
      logoStyle: {
        width:"95px"
      }
    }
  });

function MenuNav() {
    const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  

  // const scrollToSection = (sectionId) => {
  //   const sectionElement = document.getElementById(sectionId);
  //   const offset = 0;
  //   if (sectionElement) {
  //     const targetScroll = sectionElement.offsetTop - offset;
  //     sectionElement.scrollIntoView({ behavior: 'smooth' });
  //     window.scrollTo({
  //       top: targetScroll,
  //       behavior: 'smooth',
  //     });
  //     setOpen(false);
  //   }
  // };

  return (
    <div className={classes.navDiv}>
      <AppBar
      position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '5px',
              marginTop:"20px",
              bgcolor:
              'rgba(255, 255, 255, 0.8)',
              maxHeight: 40,
              borderColor: 'divider',
              
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                px: 0,
              }}
            >
              <img
                src={
                    logo
                }
                className={classes.logoStyle}
                alt="logo of sitemark"
                onClick={() => scrollToSection('home', {setOpen})}
              />
              <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', width:"100%", justifyContent: 'center' }}>
              

                <MenuItem
                   onClick={() => scrollToSection('features', {setOpen})}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body1" color="text.primary">
                  Opret profil
                  </Typography>
                </MenuItem>
                <MenuItem
                        onClick={() => scrollToSection('services', {setOpen})}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body1" color="text.primary">
                  Services
                  </Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => scrollToSection('fordele', {setOpen})}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body1" color="text.primary">
                  Fordele
                  </Typography>
                </MenuItem>
                <MenuItem
             onClick={() => scrollToSection('faqs', {setOpen})}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body1" color="text.primary">
                    FAQ
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('kontakt', {setOpen})}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body1" color="text.primary">
                  Kontakt
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
            className={classes.mobileButton}
              sx={{
                
                gap: 0.5,
                alignItems: 'center',
              }}
            >
            
            <ButlerButon text="Download app" variant="primary" onClick={() => window.open("https://2ly.link/1yA8V", "_blank")} />
             
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
           

              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <img src={MenuIcon} />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
          
                  </Box>
                  <MenuItem   onClick={() => scrollToSection('features', {setOpen})}>
                  <Typography sx={{fontFamily:'Satoshi-Bold !important'}} variant="body1" color="text.primary">
                 Opret profil</Typography> 
                  </MenuItem>
                  <MenuItem   onClick={() => scrollToSection('services', {setOpen})}>
                  <Typography sx={{fontFamily:'Satoshi-Bold !important'}} variant="body1" color="text.primary">
                  Services</Typography> 
                  </MenuItem>
                  <MenuItem   onClick={() => scrollToSection('fordele', {setOpen})}>
                  <Typography sx={{fontFamily:'Satoshi-Bold !important'}} variant="body1" color="text.primary">
                  Fordele</Typography> 
                  </MenuItem>
                 
                  <MenuItem   onClick={() => scrollToSection('faqs', {setOpen})}>
                  <Typography sx={{fontFamily:'Satoshi-Bold !important'}} variant="body1" color="text.primary"> FAQ </Typography> </MenuItem>
                  <MenuItem   onClick={() => scrollToSection('kontakt', {setOpen})}>
                  <Typography sx={{fontFamily:'Satoshi-Bold !important'}} variant="body1" color="text.primary">
                  Kontakt</Typography> 
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                  <ButlerButon text="Download app" variant="primary" onClick={() => window.open("https://www.apple.com/in/app-store/", "_blank")} />
                  </MenuItem>
                  
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}


export default MenuNav;